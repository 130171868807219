
import { loadScript, PayPalNamespace } from "@paypal/paypal-js";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Purchase",
  props: {
    description: { type: String, default: "Fibn Card Game" },
    itemAmount: { type: Number, default: 25 },
    shippingAmount: { type: Number, default: 10 },
  },
  data() {
    return { submitted: "" };
  },
  async mounted() {
    const paypal = (await loadScript({
      "client-id":
        "AfEeus-eBMjfUwJkt901u7daWEzLUFHNBN-drVyPdh3kinBWCDUVrTJZXmWTeiiWjbd4NgQnPhKD6Gt9",
      //"AQ-8DyL4M4ftxOCpA34gpy-GW4nrfSFNkDN_9IpykdjNd3tKnbm3DkN9I3ho445P47Ms7u0nfiMj4YFK",
    })) as PayPalNamespace;

    if (paypal.Buttons) {
      paypal
        .Buttons({
          style: {
            shape: "pill",
            color: "gold", //blue
            layout: "horizontal",
            label: "paypal",
          },

          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.description,
                  amount: {
                    currency_code: "USD",
                    value: (this.itemAmount + this.shippingAmount).toString(),
                    breakdown: {
                      item_total: {
                        currency_code: "USD",
                        value: this.itemAmount.toString(),
                      },
                      shipping: {
                        currency_code: "USD",
                        value: this.shippingAmount.toString(),
                      },
                      tax_total: { currency_code: "USD", value: "0" },
                    },
                  },
                },
              ],
            });
          },

          onApprove: (data, actions) => {
            return actions.order.capture().then((/* orderData */) => {
              // Full available details
              // console.log(
              //   "Capture result",
              //   orderData,
              //   JSON.stringify(orderData, null, 2)
              // );

              // https://developer.paypal.com/docs/payflow/integration-guide/test-transactions/#test-the-address-verification-service
              // 4111111111111111
              // 1/24
              // 000
              // 13894 Bangerter Pkwy
              // 200
              // Draper, UT 84020
              // 801-210-1149

              this.submitted = "success";

              // Show a success message within this page, e.g.
              // const element = document.getElementById('paypal-button-container');
              // element.innerHTML = '';
              // element.innerHTML = '<h3>Thank you for your payment!</h3>';

              // Or go to another URL:  actions.redirect('thank_you.html');
            });
          },

          onError: function (err) {
            debugger;
            console.log(err);
          },
        })
        .render("#paypal-button-container");
    }
  },
});
