
import { Options, Vue } from "vue-class-component";
import Purchase from "@/components/Purchase.vue"; // @ is an alias to /src

@Options({
  components: {
    Purchase,
  },
})
export default class Buy extends Vue {}
